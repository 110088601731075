.main-header {
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: 999;
    width: 100%;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}

.header-inner {
    position: relative;
}

.main-header .header-upper {
    position: relative;
    z-index: 5;
    padding: 35px 0;
    box-shadow: 0px 20px 60px #00000008;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}

.fixed-header .header-upper {
    padding: 10px 0;
}

.main-header .logo {
    position: relative;
    z-index: 9;
}

/** Header Main Menu */
.main-menu .navbar-collapse {
    padding: 0px;
}

.main-menu .navigation li {
    float: left;
    padding: 15px;
}

.main-menu .navigation li.dropdown .dropdown-btn {
    position: absolute;
    right: 10px;
    top: 0;
    width: 50px;
    height: 43px;
    text-align: center;
    line-height: 43px;
    cursor: pointer;
    display: none;
}

.main-menu .navigation li a {
    position: relative;
    display: block;
    font-size: 16px;
    color: #ffffff;
    line-height: 30px;
    opacity: 1;
    font-weight: 500;
    text-transform: capitalize;
    font-family: 'Jost', sans-serif;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}

.main-menu .navigation li a:after {
    position: absolute;
    content: '';
    height: 2px;
    width: 0;
    left: 50%;
    top: 100%;
    transition: 0.5s;
    background: #FFD105;
    transform: translate(-50%);
}

.main-menu .navigation li li a:hover,
.main-menu .navigation li li.current a,
.main-menu .navigation li li.current-menu-item a {
    margin-left: 5px;
}

.main-menu .navigation > li:hover > a:after,
.main-menu .navigation > li.current > a:after,
.main-menu .navigation > li.current-menu-item > a:after {
    width: 65%;
}

.menu-btn {
    background: #94DA46;


}

.menu-btn a {
    display: block;
    color: #fff;
    text-align: center;
    padding: 10px 20px;
    text-transform: uppercase;
}

.main-menu .navigation li .megamenu {
    position: absolute;
    left: 0px;
    top: 100%;
    width: 100%;
    z-index: 100;
    display: none;
    padding: 20px 0;
    background: #FFFFFF;
    -webkit-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
    box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
}

.main-menu .navigation li .megamenu:after {
    display: block;
    clear: both;
    content: "";
}

.main-menu .navigation li .megamenu ul {
    display: block;
    position: relative;
    top: 0;
    width: 100%;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.main-menu .navigation li ul {
    position: absolute;
    left: 0;
    top: 100%;
    min-width: 200px;
    z-index: 100;
    display: none;
    background: #FFFFFF;
    -webkit-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
    box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
}

.main-menu .navigation li ul li {
    width: 100%;
    padding: 7px 20px;
    border-bottom: 1px solid #F2F2F2;
}

.main-menu .navigation li ul li:last-child {
    border-bottom: none;
}

.main-menu .navigation li ul li a {
    padding: 5px 0px;
    line-height: 24px;
    color: #0B1135;
    font-weight: 500;
    text-transform: capitalize;
}

.main-menu .navigation li ul li ul {
    left: 100%;
    top: 0%;
}

.main-menu .navbar-header {
    display: none;
}

.main-menu .navbar-header .navbar-toggle {
    float: right;
    padding: 4px 0;
    cursor: pointer;
    background: transparent;
}

.main-menu .navbar-header .navbar-toggle .icon-bar {
    background: #FFD105;
    height: 2px;
    width: 30px;
    display: block;
    margin: 7px 5px;
}

.contact-number .phone-bumber {
    color: #FFD105;
}

.mobile-bg {
    background: #0B1135;
}

@media only screen and (max-width: 1250px) {
    .main-menu .navigation li {
        padding: 20px 15px;
    }
}

@media only screen and (max-width: 1199px) {
    .main-menu .navigation li {
        padding: 20px 12px;
    }
}

/* Mobile Menu */
@media only screen and (max-width: 991px) {
    .main-header .header-upper .container-fluid {
        padding: 0;
    }

    .main-header .logo-outer {
        position: absolute;
        top: 5px;
    }

    .main-menu {
        width: 100%;
    }

    .main-menu .navbar-header {
        display: block;
    }

    .main-menu .navigation {
        overflow: auto;
        max-height: 310px;
        margin: 50px 0 25px;
    }

    .main-menu .navigation li {
        float: none;
        padding: 0 20px;
    }

    .main-menu .navigation li.dropdown .dropdown-btn {
        display: block;
    }

    .main-menu .navigation li a {
        padding: 10px 10px;
        line-height: 22px;
    }

    .main-menu .navigation li.current > a {
        color: #FFD105;
    }

    .main-menu .navigation li a:after {
        display: none;
    }

    .main-menu .navigation li .megamenu {
        position: relative;
        -webkit-box-shadow: none;
        box-shadow: none;
        width: 100%;
    }

    .main-menu .navigation li .megamenu .container {
        max-width: 100%;
    }

    .main-menu .navigation li .megamenu .row {
        margin: 0px;
    }

    .main-menu .navigation li ul {
        position: relative;
        display: none;
        width: 100%;
        -webkit-box-shadow: none;
        box-shadow: none;
        background: transparent;
    }

    .main-menu .navigation li ul:after {
        display: block;
        clear: both;
        content: "";
    }

    .main-menu .navigation li ul li {
        padding: 0 20px;
        border-bottom: none;
    }

    .main-menu .navigation li ul li a {
        padding: 10px 10px;
        line-height: 22px;
        color: #0B1135;
    }

    .main-menu .navigation li ul li a:hover {
        margin-left: 5px;
    }

    .main-menu .navigation li ul li ul {
        left: auto;
    }

    .menu-btn {
        display: none;
    }
}
