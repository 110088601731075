	/*
  	Flaticon icon font: Flaticon
  	Creation date: 18/01/2020 12:55
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("../assets/fonts/Flaticon.eot");
  src: url("../assets/fonts/Flaticond41d.eot?#iefix") format("embedded-opentype"),
       url("../assets/fonts/Flaticon.woff2") format("woff2"),
       url("../assets/fonts/Flaticon.woff") format("woff"),
       url("../assets/fonts/Flaticon.ttf") format("truetype"),
       url("../assets/fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../assets/fonts/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
font-style: normal;
}

.flaticon-call-answer:before { content: "\f100"; }
.flaticon-pointed-star:before { content: "\f101"; }
.flaticon-envelope:before { content: "\f102"; }
.flaticon-play-button-arrowhead:before { content: "\f103"; }
.flaticon-right:before { content: "\f104"; }
.flaticon-security:before { content: "\f105"; }
.flaticon-play-button:before { content: "\f106"; }
.flaticon-placeholder:before { content: "\f107"; }
.flaticon-policeman:before { content: "\f108"; }
.flaticon-login:before { content: "\f109"; }
.flaticon-guard:before { content: "\f10a"; }
.flaticon-quote:before { content: "\f10b"; }
.flaticon-magnifying-glass:before { content: "\f10c"; }
.flaticon-quote-1:before { content: "\f10d"; }
.flaticon-reply:before { content: "\f10e"; }